<template>
  <div class="mt--2">
    <KTPortlet>
      <template v-slot:title>
        <h3
          class="kt-portlet__head-title"
          style="width: 100%"
          v-if="informationData.information"
        >
          {{ informationData.information.creation_date.model }} /
          {{ informationData.information.logistic.model }} /
          {{ informationData.information.tracking_code.model }}
        </h3>
      </template>
      <template v-slot:body>
        <b-overlay :show="loading">
          <b-tabs content-class="mt-3 mb-7" lazy v-model="tabIndex">
            <b-tab title="Information">
              <Information :initData="informationData"></Information>
              <b-nav tabs class="form-section">
                <b-nav-item disabled>Related Products</b-nav-item>
              </b-nav>
              <SimpleTable
                :items="items"
                :fields="fields"
                :tableId="'purchase-target-overview-list'"
                :initDataFn="initDataFn"
                style="margin-top: 20px"
              ></SimpleTable>
            </b-tab>
            <!-- <b-tab title="logs">
              <SimpleTable
                :items="items2"
                :fields="fields2"
                :tableId="'purchase-target'"
              ></SimpleTable>
            </b-tab> -->
          </b-tabs>
          <div class="console" v-if="ifConsole">
            <b-overlay :show="loading">
              <!-- v-if="
                  informationData.information.status.display_text == 'Incoming'
                " -->
              <DeliveryReceived
                v-if="0"
                class="mr-2"
                :warehouseEntranceId="warehouse_entrance_id"
                :initFn="initDataFn"
              ></DeliveryReceived>
              <router-link
                :to="{
                  name: 'warehouse-entrance-list'
                }"
                class="btn btn-secondary"
                >Back to List
                <b-icon icon="reply-fill" aria-hidden="true"></b-icon
              ></router-link>
            </b-overlay>
          </div>
        </b-overlay>
      </template>
    </KTPortlet>
  </div>
</template>

<script>
import KTPortlet from '@/views/content/Portlet';
import Information from '@/views/content/Detail/WarehouseEntrance/Information/Index.vue';
import apiBus from '@/common/apiBus/index';
import SimpleTable from '@/components/Table/Simple.vue';
// import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
import DeliveryReceived from '@/views/content/DeliveryReceived/Index.vue';
// import Link from '@/views/content/NewAdd/Information/Link';
export default {
  name: 'WarehouseDetail',
  components: { KTPortlet, Information, SimpleTable, DeliveryReceived },
  data() {
    return {
      tabIndex: 0,
      loading: false,
      informationData: {},
      warehouse_entrance_id: null,
      id: null,
      purchase_id: null,
      showModal: false,
      modalloading: false,
      submitDisabled: false,
      ifConsole: false,
      items: [],
      fields: [
        {
          key: 'purchase_information',
          label: 'Purchase Information'
        },
        {
          key: 'order_information',
          label: 'Order Information'
          // sortDesc: true
        },
        {
          key: 'image',
          label: 'Image',
          variant: 'w-110'
        },
        {
          key: 'article_information',
          label: 'Article Information',
          variant: 'mx-300'
        },
        {
          key: 'qty',
          label: 'Delivery Quantity'
        },
        {
          key: 'action',
          label: ''
        }
      ],
      status: null,
      save_data: [],
      options: {}
    };
  },

  methods: {
    initDataFn() {
      this.loading = true;
      this.voidDisabled = false;
      return apiBus.warehouse
        .warehouseEntranceDetail({
          warehouse_entrance_id: this.warehouse_entrance_id
        })
        .then(data => {
          this.loading = false;
          this.ifConsole ? '' : (this.ifConsole = true);
          this.informationData = this.handleData(data.data.data);
          this.status = data.data.data.status;
          this.items = this.initTable(data.data.data.purchase_list);

          return this.informationData;
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        });
    },
    initTable(data) {
      var tableData = [];
      data.forEach(item => {
        let {
          id,
          product_info,
          qty,
          purchase_id,
          order_id,
          article_review_id,
          purchase_date,
          reserve_no,
          order_date,
          cps_order_url,
          position
        } = item;
        let {
          brand,
          image_url,
          brand_article_number,
          product_name,
          product_name_en,
          color,
          color_name,
          size,
          pos_article_no
        } = product_info;
        tableData.push({
          purchase_information: {
            purchase_id: purchase_id,
            purchase_date: purchase_date,
            reserve_no: reserve_no
          },
          order_information: {
            cps_order_number: order_id,
            order_date: order_date,
            cps_order_url: cps_order_url,
            position: position
          },
          order_id: order_id,
          image: image_url,
          qty: qty,
          article_information: {
            brand: brand,
            brand_article_number: brand_article_number,
            product_name:
              product_name_en != '' && product_name_en != null
                ? product_name_en
                : product_name,
            color: color,
            color_name: color_name,
            size: size,
            pos_article_no: pos_article_no
          },
          id: id,
          article_review_id: article_review_id,
          action: 'WarehouseEntranceDetail',
          status: this.status,
          warehouse_entrance_id: this.warehouse_entrance_id
        });
      });
      return tableData;
    },
    handleData(data) {
      console.log(data);
      var handle_data = {};
      let {
        creation_date,
        delivery_to,
        id,
        logistic,
        purchase_id,
        reception_date,
        receiver,
        status,
        supplier_name,
        // channel,
        tracking_code
      } = data;
      this.purchase_id = purchase_id;
      this.id = id;
      handle_data = {
        information: {
          creation_date: {
            type: 'date',
            text: 'Creation Date',
            model: this.initTime(creation_date),
            disabled: true,
            hide: false
          },
          status: {
            type: 'text',
            text: 'Status',
            display_text: status,
            class: 'status-text status',
            text_class: 'text color_' + status.replace(' ', '_')
          },
          logistic: {
            type: 'input',
            text: 'Logistics',
            model: logistic,
            disabled: true
          },
          supplier_name: {
            type: 'input',
            text: 'Supplier Name',
            model: supplier_name,
            disabled: true
          },
          // channel: {
          //   type: 'input',
          //   text: 'Channel',
          //   model: channel,
          //   disabled: true
          // },

          tracking_code: {
            type: 'input',
            text: 'Tracking No.',
            icon: 'search',
            // rlink: {
            //   name: 'purchase-overview-detail-index',
            //   params: {
            //     purchaseId: 1
            //   }
            // },
            model: tracking_code,
            disabled: true
          },
          delivery_to: {
            type: 'input',
            text: 'Delivery to',
            model: delivery_to,
            disabled: true
          },
          reception_date: {
            type: 'date',
            text: 'Reception Date',
            model: this.initTime(reception_date),
            disabled: true
          },
          receiver: {
            type: 'input',
            text: 'Receiver',
            model: receiver,
            disabled: true
          }
        }
      };

      return handle_data;
      // var handle_data = {};
    },
    initTime(data) {
      var time = data;
      if (time.indexOf('.') > 0) {
        time = `${time.split('.')[2]}-${time.split('.')[1]}-${
          time.split('.')[0]
        }`;
      }
      return time;
    },
    logsList() {
      apiBus.purchase
        .purchaseTargetLogs({
          purchase_target_id: this.id
        })
        .then(data => {
          this.items2 = data.data.data.list;
          Object.keys(this.items2).forEach(key => {
            this.items2[key].action_text = this.items2[key].action;
          });
        })
        .catch(error => {
          console.log(error);
        });
    },
    init() {
      let { warehouse_entrance_id } = this.$route.params;
      this.warehouse_entrance_id = warehouse_entrance_id;
      this.initDataFn();
      // this.logsList();
    }
  },

  computed: {},
  mounted() {
    this.init();
  }
};
</script>
<style scoped></style>
