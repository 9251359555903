<template>
  <div>
    <b-button
      variant="primary"
      :size="size"
      @click="clickModal"
      :disabled="disabled"
    >
      Reception
      <i class="far fa-check-circle"></i>
      <!-- <b-icon icon="folder-check" aria-hidden="true"></b-icon> -->
    </b-button>
    <b-modal
      v-model="showModal"
      centered
      no-close-on-backdrop
      scrollable
      title="Delivery Reception"
      id="invoice_detail"
      ref="modal"
    >
      <b-overlay :show="loading">
        <p class="reconfirm">
          Are you sure that the delivery is received?
        </p>
      </b-overlay>
      <template v-slot:modal-footer="{ close }">
        <b-button variant="primary" @click="yes()" :disabled="submitDisabled">
          YES
        </b-button>
        <b-button variant="secondary" @click="close()">
          NO
        </b-button>
      </template></b-modal
    >
  </div>
</template>
<script>
import apiBus from '@/common/apiBus/index';
// import SelectLoading from '@/components/Loading/Index';
// import { mapState } from 'vuex';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
// import { RENEW_BASE_CONFIG } from '@/store/modules/baseconfig/actions';
export default {
  name: 'CreatePurchase',
  data: function() {
    return {
      showModal: false,
      loading: false,
      disabled: false,
      submitDisabled: false
    };
  },
  components: {},
  props: ['warehouseEntranceId', 'size', 'initFn'],
  methods: {
    clickModal() {
      this.showModal = true;
    },
    yes() {
      this.submitDisabled = true;
      this.loading = true;
      apiBus.warehouse
        .warehouseEntranceReception({
          warehouse_entrance_id: this.warehouseEntranceId
        })
        .then(data => {
          console.log(data);
          this.submitDisabled = false;
          this.loading = false;
          this.showModal = false;
          this.initFn();
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'success', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Successfully!' // 提示内容
          });
          this.showModal = false;
        })
        .catch(error => {
          this.submitDisabled = false;
          this.loading = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
        });
    },

    init() {}
  },
  computed: {
    // ...mapState({
    //   baseConfig: state => state.baseconfig.baseconfig
    // })
  },
  mounted() {
    this.init();
  }
};
</script>
<style scoped></style>
